<template>
    <section id="content">

        <div class="content-wrap pb-0">

            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-6">
                        <div class="badge badge-pill badge-default">UI/UX</div>
                        <h2 class="display-6 font-weight-bold">Our team of professionals makes only unique offers to each client.</h2>
                        <p>It is because all the projects are unique in their own way.The qualitative UX includes a thought-out design, which in its’ turn is a connection between the user and the functionality of the product. </p>

                        <p> Finally, this allows the product to become truly productive and generative. For a long-term perspective, the smart business needs exactly such solutions that our team implements separately for each project. The company Crafty Tech, SRLApp is the best consulting UI company. We have a huge practice of designing the custom functions and maintaining the functionality at every stage of the application development. The main part of our work is to focus on the needs of the end users. Our user interface design services help our partners to develop a reliable and a scalable user interface which allows them to maximize their return on the investment and to meet their business goals. </p>

                        <p> Our team is focused on developing the projects which will provide the best interaction of the product with the end user. Our experience allows us to identify the needs of each individual, developed application using the full power of UX/UI design for creating impressions which people like! Combining the ease of use and the enthusiasm from use is our final goal!</p>

                    </div>
                    <div class="col-lg-6 mt-4 mt-lg-0">
                        <img src="../../assets/demos/seo/images/services/seo.svg" alt="Image">
                    </div>
                </div>

                <div class="clear line my-6"></div>


            </div>

            <div class="section parallax parallax-bg m-0 border-top-0 section-one" data-rellax-speed="2" style="" data-bottom-top="background-position:0px 0px;" data-top-bottom="background-position:0px -200px;">
                <div class="container center clearfix">

                    <div class="heading-block color-white">
                        <h2>The processes we follow:</h2>
                    </div>

                    <div class="row justify-content-center col-mb-30">
                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">1</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>Analysis and Marketing Research<span class="subtitle">The process UX starts from the collection, analysis and definition of the goals and requirements in order to achieve the desired results.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">2</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott">Information architecture<span class="subtitle">We structure and organize the information based on the concept of resourcefulness, so we focus on what the users need to find quickly what they are looking for.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">3</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott">Frameworks<span class="subtitle">We display the supposed structure of the whole product in the frameworks.</span></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">4</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott">Prototypes<span class="subtitle">We create the detailed clickable prototypes so that before creating the application you can see how it will look, where the buttons and all the working functionality will be placed..</span></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">5</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott">User interface design<span class="subtitle">We develop only those designs which are understandable and convenient to apply for the user.</span></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">6</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3 class="nott">User Interfaces<span class="subtitle">In order to attract and to force the user to stay in the application, we will combine the functions and the details of the product together into one interface.</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>
    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "uiux",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'UIUX',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>
    .fbox-bg.fbox-center {
        min-height: 240px;
        background: white;
    }
    .color-white {
        color:white;
    }
    .section-one {
      /*  background: url('../../assets/images/parallax/3.jpeg') center center; */
        background-image: -webkit-linear-gradient(
                49deg
                , rgb(10,97,215) 0%, rgb(0,221,254) 100%);
        padding: 100px 0;
    }
    .section-one .fbox-bg.fbox-center .fbox-icon {
        background: transparent;
    }
</style>